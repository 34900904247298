var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"programme common-block"},[_c('div',{staticClass:"common-title-n"},[_c('div',{staticClass:"common-title-l"},[_c('img',{attrs:{"src":require("@/images/jobOverview_icon.png")}}),_c('div',[_vm._v(_vm._s(_vm.$t("工作概览")))])]),_c('div',{staticClass:"common-title-r"},[_c('div',{staticClass:"time"},[_c('div',{staticClass:"week",class:{ active: _vm.dataText == 'today' },on:{"click":function($event){return _vm.onDateShortcut('today')}}},[_vm._v(" "+_vm._s(_vm.$t("今日"))+" ")]),_c('div',{staticClass:"week",class:{ active: _vm.dataText == 'week' },on:{"click":function($event){return _vm.onDateShortcut('week')}}},[_vm._v(" "+_vm._s(_vm.$t("本周"))+" ")]),_c('div',{staticClass:"month",class:{ active: _vm.dataText == 'month' },on:{"click":function($event){return _vm.onDateShortcut('month')}}},[_vm._v(" "+_vm._s(_vm.$t("本月"))+" ")])])])]),(_vm.current == 1)?_c('div',{staticClass:"options"},[_c('div',{staticClass:"channel",on:{"click":function($event){_vm.actionSheetShow = true}}},[_c('span',[_vm._v(_vm._s(_vm.$t("渠道"))+"："+_vm._s(_vm.actions[_vm.sheetIndex].name))]),_c('van-icon',{staticClass:"play",attrs:{"name":"play"}})],1)]):_vm._e(),_c('van-swipe',{attrs:{"indicator-color":"#0040C5"},on:{"change":(index) => {
        _vm.current = index;
      }}},[_c('van-swipe-item',[_c('div',{staticClass:"overView"},[_c('div',{staticClass:"overView-l",on:{"click":function($event){return _vm.gotoPath('LEAD_TARGET', _vm.leadTaegetShow)}}},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("有效线索量")))]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.leadTaegetShow))])]),_c('div',{staticClass:"overView-r"},_vm._l((_vm.leadChannels),function(lead,idx){return _c('div',{key:lead.code,class:['overView-r-i', idx !== 0 ? 'grap' : ''],on:{"click":function($event){return _vm.gotoPath('channel', lead)}}},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(lead.name))]),_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(`${lead.finished}`)+_vm._s(lead.target ? "/" : "")+_vm._s(lead.target)+" ")])])}),0)]),_c('div',{staticClass:"subdivision"},_vm._l((_vm.targetStats),function(item,index){return _c('div',{key:index,staticClass:"subdivision-i",on:{"click":function($event){return _vm.gotoPath(item.code,item)}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),(['PASSENGER_TARGET', 'FOLLOW_TARGET'].includes(item.code))?_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(item.finished)+" ")]):_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(item.target === undefined ? item.finished : `${item.finished}/${item.target || 0}`)+" ")])])}),0)]),_c('van-swipe-item',[_c('div',{staticStyle:{"min-height":"215px"}},[(_vm.userInfo.id)?_c('Charts',{ref:"charts",attrs:{"filterParams":_vm.filterParams,"from":'sales',"formData":_vm.formData}}):_vm._e()],1)])],1),_c('van-action-sheet',{style:({
      left: '50%',
      transform: 'translateX(-50%)',
      'max-width': '500px',
    }),attrs:{"actions":_vm.actions,"cancel-text":_vm.$t('取消'),"close-on-click-action":""},on:{"select":_vm.onChannelSelect},model:{value:(_vm.actionSheetShow),callback:function ($$v) {_vm.actionSheetShow=$$v},expression:"actionSheetShow"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }