<template>
  <div class="commmonFunction common-block">
    <div class="common-title-n">
      <div class="common-title-l">
        <img src="@/images/commonFunction_icon.png" />
        <div>{{ $t("常用功能") }}</div>
      </div>
    </div>
    <div class="module">
      <div v-for="(item, index) in moduleList" :key="index" class="module-i">
        <div class="image">
          <img :src="item.iconUrl" @click="goApplication(item)" />
          <div v-if="appToDo[item.code]" class="appToDo">
            {{ appToDo[item.code] > 99 ? "99+" : appToDo[item.code] }}
          </div>
        </div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import salesAssistantService from '@/services/salesAssistant'
import { v4 as uuidv4 } from 'uuid'
export default {
  data() {
    return {
      appToDo: {},
    }
  },
  computed: {
    moduleList() {
      let arr = [
        {
          id: '1703682696622833666',
          children: [],
          parentId: '1',
          sort: 0,
          code: '1000003',
          name: this.$t('线索明细'),
          clientType: 2,
          resourcesType: 1,
          groupName: this.$t('销售工具'),
          iconUrl:
            'https://wework.qpic.cn/wwpic/73721_lUh0UpQ_T6an3EJ_1698832836/0',
          resourcesUri: '/clue-list',
          qwTag: 'dsso_lead',
          status: 1,
        },
        {
          id: '1703682696622833667',
          children: [],
          parentId: '1',
          sort: 3,
          code: '1000006',
          name: this.$t('预约明细'),
          clientType: 3,
          resourcesType: 1,
          groupName: this.$t('销售工具'),
          iconUrl:
            'https://wework.qpic.cn/wwpic3az/159109_T-g9gmUiQgiXyxM_1702635883/0',
          resourcesUri: '/appointment',
          qwTag: 'dsso_appointment',
          status: 1,
        },
        {
          id: '1732289626042970114',
          children: [],
          parentId: '1',
          sort: 6,
          code: '1000013',
          name: this.$t('订单明细'),
          clientType: 3,
          resourcesType: 1,
          groupName: this.$t('销售工具'),
          iconUrl:
            'https://wework.qpic.cn/wwpic3az/351357_QzUX_zYmRbSvbUV_1701228264/0',
          resourcesUri: '/order-list',
        
        },
        {
          id: '1790655137759686657',
          children: [],
          parentId: '1',
          sort: 23,
          code: '2000008',
          name: this.$t('任务明细'),
          clientType: 3,
          resourcesType: 1,
          groupName: this.$t('销售工具'),
          iconUrl:
            'https://wework.qpic.cn/wwpic3az/150636_icpcypyERo2r6Gd_1715759986/0',
          resourcesUri: '/taskManage',
          status: 1,
        }
      ]
      if (process.env.VUE_APP_ENV === 'uat') {
        const addArr = [
          {
            id: uuidv4(),
            children: [],
            parentId: '1',
            sort: 24,
            code: '2000025',
            name: this.$t('智能巡检'),
            clientType: 3,
            resourcesType: 1,
            groupName: this.$t('销售工具'),
            iconUrl:
            'https://wework.qpic.cn/wwpic3az/100166_rRyFaC1ZSQGMe2m_1725417868/0',
            resourcesUri: '/dshowRoomh5Factory',
            status: 1,
          },
          {
            id: uuidv4(),
            children: [],
            parentId: '1',
            sort: 26,
            code: '2000027',
            name: this.$t('低满工单'),
            clientType: 3,
            resourcesType: 1,
            groupName: this.$t('门店管理'),
            iconUrl:
            'https://wework.qpic.cn/wwpic3az/150636_icpcypyERo2r6Gd_1715759986/0',
            resourcesUri: '/lowFullWorkOrderFactory',
            status: 1,
          },
        ]
        arr = arr.concat(addArr)
      }
      return arr
    }
  },
  mounted() {
    this.getModule()
  },
  methods: {
    // 获取当前设置模块
    async getModule() {
      // const result = await salesAssistantService.allEmpResource({})
    },

    // 跳转应用
    async goApplication({ code, resourcesUri }) {
      const env = process.env.VUE_APP_ENV
      this.$router.push({
        path: resourcesUri,
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "./common.less";
.commmonFunction {
  padding: 10px;
  .module {
    display: flex;
    align-items: stretch;
    height: auto;
    // margin-top: 12px;
    flex-wrap: wrap;
    .module-i {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: 63px;
      font-size: 12px;
      width: 25%;
      margin-top: 12px;
      // flex: 1;
      .image {
        width: 36px;
        height: 36px;
        margin-bottom: 8px;
        position: relative;
        .appToDo {
          position: absolute;
          right: 0;
          top: 0;
          width: 22px;
          height: 18px;
          border-radius: 18px;
          background: red;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: -8px;
          margin-top: -8px;
          color: #ffffff;
          font-size: 10px;
        }
      }
      img {
        width: 38px;
        height: 38px;
      }
      .name {
        width: 100%;
        text-align: center;
        margin-top: 8px;
        overflow: hidden;
        flex: 1;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
