var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"performanceRank common-block",attrs:{"id":"performanceRank"}},[_c('div',{staticClass:"common-title-n"},[_c('div',{staticClass:"common-title-l"},[_c('img',{attrs:{"src":require("@/images/performanceRank_icon.png")}}),_c('div',[_vm._v(_vm._s(_vm.$t("业绩排行")))])]),_c('div',{staticClass:"common-title-r"},[_c('van-popover',{staticClass:"popoverBox",attrs:{"trigger":"click","placement":"bottom-end","actions":_vm.rangeList},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('span',{staticClass:"textShow"},[_vm._v(" "+_vm._s(_vm.rangeShow)+" "),_c('van-icon',{attrs:{"name":"play"}})],1)]},proxy:true}]),model:{value:(_vm.showPopover2),callback:function ($$v) {_vm.showPopover2=$$v},expression:"showPopover2"}},[_c('div',{staticClass:"van-popover__content",staticStyle:{"width":"fit-content"}},[_vm._l((_vm.rangeList),function(item,index){return [_c('div',{key:index,staticClass:"van-popover__action",on:{"click":function($event){$event.stopPropagation();return _vm.changeRange(item)}}},[_c('div',{staticClass:"van-popover__action-text van-hairline--bottom"},[_vm._v(" "+_vm._s(item.text)+" ")])])]})],2)])],1)]),_c('van-tabs',{attrs:{"ellipsis":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabList),function(item,index){return _c('van-tab',{key:index,attrs:{"title":item.title,"name":item.value}},[_c('div',{staticClass:"rankContent"},[_vm._l((_vm.dataList),function(data){return _c('div',{key:data.userId,class:[
            'rankItem',
            data.empId === _vm.userInfo.id ||
            data.empName === _vm.userInfo.name + _vm.$t('组')
              ? 'active'
              : '',
          ]},[_c('div',{staticClass:"ranking"},[([1, 2, 3].includes(data.rank))?_c('img',{attrs:{"src":_vm.getUrl[data.rank - 1]}}):_c('div',[_vm._v(_vm._s(data.rank))])]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(data.empName)+" ")]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(data.count))])])}),(_vm.dataListAll.length > 10)?_c('div',{staticClass:"more",on:{"click":_vm.onShow}},[_vm._v(" "+_vm._s(_vm.showMore ? _vm.$t("收起") : _vm.$t("展开"))+" "),(_vm.showMore)?_c('van-icon',{attrs:{"name":"arrow-up"}}):_c('van-icon',{attrs:{"name":"arrow-down"}})],1):_vm._e(),(_vm.query && _vm.dataList.length == 0)?_c('div',{staticClass:"common-noData",staticStyle:{"height":"212px"}},[_vm._v(" "+_vm._s(_vm.$t("暂无数据!"))+" ")]):_vm._e()],2)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }